import { Game } from "../components/Game";

function HomePage() {

return (
    <Game />
);
}

export { HomePage };
